<script setup lang="ts">
import type { Picture } from '@yescapa-dev/ysc-api-js/legacy'

interface Props {
  note: number | null
  photos?: Picture[]
  isLargePhotos?: boolean
}

withDefaults(defineProps<Props>(), {
  photos: () => [],
  isLargePhotos: false,
})
const slots = useSlots()
const emits = defineEmits<{
  'photo-click': [index: string]
}>()
</script>

<template>
  <div class="max-w-prose space-y-4">
    <div class="flex items-center space-x-4">
      <slot name="avatar" />

      <div class="grow">
        <p
          v-if="slots['user-name']"
          class="font-semibold"
        >
          <slot name="user-name" />
        </p>
        <div class="mt-1 flex items-center justify-between">
          <div class="flex items-center">
            <YscIconsStarSolid class="mr-1 inline h-4 w-4 text-pink-500" />
            {{ note }}
          </div>
          <p
            v-if="$slots['user-description']"
            class="text-right text-sm text-gray-500"
          >
            <slot name="user-description" />
          </p>
        </div>
      </div>
    </div>

    <div v-if="slots.description">
      <slot name="description" />
    </div>

    <AppContentScroller
      v-if="photos.length"
      class="space-x-4"
    >
      <TwicImg
        v-for="photo in photos"
        :key="photo.id"
        :src="twicpicsPath(photo.path)"
        :ratio="isLargePhotos ? '4/3' : '1'"
        :class="isLargePhotos ? 'w-36' : 'w-20'"
        :alt="$t('commons.links.community')"
        class="shrink-0 rounded"
        placeholder="maincolor"
        @click.prevent="emits('photo-click', photo.path)"
      />
    </AppContentScroller>

    <div
      v-if="slots['private-message']"
      class="space-y-4 rounded-lg bg-gray-50 p-4"
    >
      <div class="flex items-center space-x-2">
        <YscIconsLock class="h-4 w-4 text-red-600" />
        <span class="text-sm font-semibold text-black">{{ $t('data.review.private_text.label') }}</span>
      </div>

      <slot name="private-message" />
    </div>
  </div>
</template>
